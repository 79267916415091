import React from 'react';
import Helmet from 'react-helmet';

import Layout from 'components/Layout';
import Container from 'components/Container';
import Banner from 'components/Banner';
import Signup from 'components/Signup';

import index_banner from 'assets/images/pd_pattern_grey.png';

const IndexPage = () => {
  return (
    <Layout pageName="landing">
      <Helmet>
        <title>pd_dev</title>
      </Helmet>
      {/* <Banner source={index_banner}></Banner> */}
      <Container>
        <h1>Du interessierst dich für die Weiterentwicklung von Perspective Daily?</h1>
        <h3>Abonniere den Dev-Newsletter von Perspective Daily und bleib auf dem Laufenden.</h3>
      </Container>
      <Signup />
    </Layout>
  );
};

export default IndexPage;
