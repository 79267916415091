import React from 'react';

import Mailchimp from 'components/mailchimpForm';

const Signup = () => {
  return (
    <section className="signup">
      <div className="form">
        <div className="signupcontainer">
          <Mailchimp
            action="https://dev.perspective-daily.de/post?u=6f57e8723e7e321c9dcdb833b&amp;id=32dc86798a"
            fields={[
              {
                name: 'EMAIL',
                placeholder: 'Email',
                type: 'email',
                required: true
              },
              {
                name: 'FNAME',
                placeholder: 'Vorname',
                type: 'text',
                required: false
              }
            ]}
            messages={{
              sending: 'Versende Daten...',
              success: 'Super, das hat geklappt. Schau einfach kurz in dein Postfach, da wartet deine Bestätigungsmail',
              error: 'Irgendwas ist schiefgelaufen :-(',
              empty: 'Deine E-Mail scheint zu fehlen.',
              duplicate: 'Too many subscribe attempts for this email address',
              button: 'Abonnieren'
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Signup;
